import { isProdEnv } from "@/config";

export const adUnitSuffix = "ag"; // top-ag | mid-page-ag | bottom-ag
export const displayPlaceholders = !isProdEnv;

// Map of ad sizes based on screen resolution
// see: https://developers.google.com/publisher-tag/guides/ad-sizes#responsive_ads
export const viewportDesktop = [1192, 670];
export const viewportTablet = [790, 592];
export const viewportMobile = [360, 0];

export const sizesMobile = [
  [320, 50],
  [300, 250],
];

export const sizesAds = {
  default: "fluid",
  billboard: {
    sm: [viewportMobile, sizesMobile],
    md: [viewportTablet, [728, 90]],
    lg: [viewportDesktop, [970, 250]],
  },
  leaderboard: {
    sm: [viewportMobile, sizesMobile],
    md: [viewportTablet, [728, 90]],
    lg: [viewportDesktop, [728, 90]],
  },
  halfPage: {
    sm: [viewportMobile, sizesMobile],
    md: [viewportTablet, [300, 250]],
    lg: [viewportDesktop, [300, 600]],
  },
  mediumRectangle: {
    sm: [viewportMobile, [[300, 250]]],
    md: [viewportTablet, [300, 250]],
    lg: [viewportDesktop, [300, 250]],
  },
  microBar: {
    sm: [viewportMobile, [88, 31]],
    md: [viewportTablet, [88, 31]],
    lg: [viewportDesktop, [88, 31]],
  },
};
