import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  root: {
    position: "relative",

    "&$succeeded": {
      "& $form": {
        opacity: 0,
        zIndex: 0,
      },

      "& $titleText": {
        opacity: 0,
        zIndex: 0,
      },

      "& $successMessage": {
        opacity: 1,
        zIndex: 10,
      },
    },
  },

  succeeded: {},

  signup: {},

  form: {
    display: "flex",
    opacity: 1,
    position: "relative",
    transition: theme.transitions.create("opacity"),
    zIndex: 1,
  },

  inputWrapper: {
    border: "none",

    "& $notchedOutline": {
      border: "none",
    },
  },

  placeholder: {
    ...theme.typography.caption,

    "& input": {
      textAlign: "center",
    },
  },

  notchedOutline: {},

  titleText: {
    paddingBottom: 24,
    textAlign: "center",
    opacity: 1,
    transition: theme.transitions.create("opacity"),
  },

  input: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.atlasGuru.boxShadow(),
    display: "flex",
    height: 58,
    justifyContent: "center",
  },

  successMessage: {
    opacity: 0,
    position: "absolute",
    top: 0,
    transition: theme.transitions.create("opacity"),
    width: "100%",
    zIndex: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& h3": {
      marginBottom: "0.5em",
    },
  },

  button: {
    display: "inline-block",
    height: "58px",
    marginBottom: 17,
    padding: `0 ${theme.gutter}px`,
    minWidth: "auto",

    [theme.breakpoints.up("sm")]: {
      flex: "0 0 160px",
    },
  },

  buttonError: {
    backgroundColor: theme.palette.error.main,
  },

  loading: {
    color: theme.palette.common.white,
    left: theme.spacing(1),
    position: "relative",
    top: theme.spacing(0.5),
  },
}));

export default styles;
