import { Button, Typography, Image, Picture } from "@/ui";
import { IconAIStar, IconAIStars } from "@/icons";

import useStyles from "./styles";
import { staticUrl } from "@/lib/utils";

const BannerAiPromo = ({ isHome }) => {
  const classes = useStyles();

  return (
    <aside className={`${classes.root} ${isHome ? classes.rootHome : ""}`}>
      <Picture
        alt="Hero"
        loading="eager"
        fetchPriority="high"
        className={`${classes.hero} ${isHome ? classes.heroHome : ""}`}
        sizes={{
          md: {
            cloudId:
              "https://res.cloudinary.com/atlasguruprod/image/upload/f_auto,q_auto/v1/site-prod/misc/i9vr9vccuu2ey2o2iw7r",
            width: 1168,
            height: 657,
          },
          sm: {
            cloudId:
              "https://res.cloudinary.com/atlasguruprod/image/upload/f_auto,q_auto/v1/site-prod/misc/mhmmpnwi4hcsw5dj08f2",
            width: 960,
            height: 540,
          },
          xs: {
            cloudId:
              "https://res.cloudinary.com/atlasguruprod/image/upload/f_auto,q_auto/v1/site-prod/misc/ln15phokzhex2t0ut2x0",
            width: 390,
            height: 666,
          },
        }}
      />

      <div
        className={`${classes.content} ${isHome ? classes.contentHome : ""}`}
      >
        <div className={classes.left}>
          <IconAIStars
            classes={{ root: classes.smallUp }}
            size="lg"
            viewBox="0 0 50 50"
          />

          <div>
            <Typography
              component="p"
              variant="h1"
              className={`${classes.heading} ${classes.smallUp} ${
                isHome ? classes.headingHome : ""
              }`}
            >
              Your AI Travel Advisor
            </Typography>

            <Typography
              component="p"
              variant="h1"
              className={`${classes.heading} ${classes.extraSmallDown} ${
                isHome ? classes.headingHome : ""
              }`}
            >
              <span>Your AI Travel</span>{" "}
              <span className={classes.smallIcon}>
                <span>Advisor</span>{" "}
                <IconAIStars size="md" viewBox="0 0 50 50" />
              </span>
            </Typography>

            <Typography className={classes.message} variant="caption">
              {`Build your trip for free with AI. Then book it with `}
              <br className={classes.smallUp} />
              {`a travel advisor to make it real.`}
            </Typography>
          </div>
        </div>
        <div className={`${classes.right} ${isHome ? classes.rightHome : ""}`}>
          <div className={classes.avatarContainer}>
            <Image
              src={staticUrl(
                require("@/static/img/how-it-works-avatar-01.jpg")
              )}
              className={classes.userAvatarImg}
              height={32}
              width={32}
            />
            <Image
              src={staticUrl(
                require("@/static/img/how-it-works-avatar-02.jpg")
              )}
              className={classes.userAvatarImg}
              height={32}
              width={32}
            />
            <Image
              src={staticUrl(
                require("@/static/img/how-it-works-avatar-03.jpg")
              )}
              className={classes.userAvatarImg}
              height={32}
              width={32}
            />
            <Image
              src={staticUrl(
                require("@/static/img/how-it-works-avatar-04.jpg")
              )}
              className={classes.userAvatarImg}
              height={32}
              width={32}
            />
            <Image
              src={staticUrl(
                require("@/static/img/how-it-works-avatar-05.jpg")
              )}
              className={classes.userAvatarImg}
              height={32}
              width={32}
            />
          </div>

          <Button
            href="/ai-itinerary-planner/"
            className={classes.button}
            variant="contained"
            classes={{
              label: classes.buttonLabel,
            }}
          >
            <IconAIStar size="sm" viewBox="0 0 45 45" />
            Try It Now
          </Button>
        </div>
      </div>
    </aside>
  );
};

export default BannerAiPromo;
