import { makeStyles } from "@material-ui/core/styles";

import { sizesAds, displayPlaceholders } from "./constants";

// To avoid layout shifts, the ad dimensions are defined in css using sizesAds
// see: https://developers.google.com/publisher-tag/guides/minimize-layout-shift
/* eslint-disable dot-notation */
const generateResponsiveSizes =
  (tabletBreakpoint, desktopBreakpoint) => (variant) => ({
    maxWidth: sizesAds[variant]["sm"][1][0][0],
    minHeight: sizesAds[variant]["sm"][1][0][1],

    [tabletBreakpoint]: {
      maxWidth: sizesAds[variant]["md"][1][0],
      height: sizesAds[variant]["md"][1][1],

      "& > $placeholder": {
        width: sizesAds[variant]["md"][1][0],
      },
    },

    [desktopBreakpoint]: {
      maxWidth: sizesAds[variant]["lg"][1][0],
      height: sizesAds[variant]["lg"][1][1],

      "& > $placeholder": {
        width: sizesAds[variant]["lg"][1][0],
      },
    },
  });

const useStyles = makeStyles((theme) => {
  const generateBreakpoints = generateResponsiveSizes(
    "@media (min-width:790px)", // large tablet++
    theme.breakpoints.up("lg")
  );

  return {
    root: {
      display: "flex",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",

      "& > div": {
        textAlign: "center",
      },
    },

    placeholder: {
      background: displayPlaceholders ? theme.palette.grey[200] : "transparent",
      position: "absolute",
      height: "100%",
      top: 0,
      left: 0,
      width: "100%",
    },

    hidden: {
      display: "hide",
    },

    // variant=billboard
    billboard: generateBreakpoints("billboard"),

    // variant=leaderboard
    leaderboard: generateBreakpoints("leaderboard"),

    // variant=halfPage
    halfPage: {
      justifyContent: "stretch",
      alignItems: "stretch",
      ...generateBreakpoints("halfPage"),
    },

    // variant=mediumRectangle
    mediumRectangle: generateBreakpoints("mediumRectangle"),

    // variant=microBar
    microBar: generateBreakpoints("microBar"),
  };
});

export default useStyles;
