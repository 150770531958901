/*
 * Ads Component Props
 *
 * position: Ad Unit Zones
 * see: https://admanager.google.com/22468637909#inventory/ad_unit/list/filter=eJxdjUELwjAMRv9Lzj3oRLS7jaFQkKlMBJExiotYHK2kqSCj_30V3MXb9x68ZIC76RmpdLYzbJz1kF8HeOIHcjhoQsuqAwHuhaTZUbKqTvzWfcAEWbZcrxZSytkcopjCmjUH_5-1RXVp99up3hnP6RkU5UmdN8mq6jeb2AjwqOn2OAak70WII0VwNW0.
 * Ad unit were defined based on the position of the ad, those can be:
 * Bottom (bottom-ag)
 * Mid-Page (mid-page-ag)
 * Right Rail (right-rail-ag)
 * Top (top-ag)
 *
 * slotTarget: Key-Values to configure targeting
 * see: https://developers.google.com/publisher-tag/guides/key-value-targeting#set_targeting
 *
 * variant: Type of Ad
 * Common Ads Sizes (variant={ID}):
 *  Desktop    /    Mobile    –  ID
 * 970 x 250   /   320 x 50   –  billboard
 * 728 x  90   /   320 x 50   –  leaderboard
 * 300 x 600   /   320 x 50   –  halfPage
 * 300 x 250   /   300 x 250  –  mediumRectangle
 *  88 x 31    /    88 x 31   –  microBar
 */

import { displayAds } from "@/config";

import AdUnit from "./AdUnit";

const Ads = (props) => {
  return displayAds !== "enabled" ? null : <AdUnit {...props} />;
};

export default Ads;
