import { makeStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    maxWidth: "1192px",
    position: "relative",
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",
    height: "180px",

    [theme.breakpoints.down("sm")]: {
      height: "260px",
      margin: `0 -${theme.spacing(3)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      height: "300px",
    },
  },
  rootHome: {
    height: "330px",
  },
  hero: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "100%",
    filter: "brightness(1.25)",

    [`@media (max-width:${theme.breakpoints.values.xsm}px)`]: {
      width: "130%",
      bottom: 0,
    },

    [theme.breakpoints.only("xs")]: {
      bottom: "-6vw",
    },
    [theme.breakpoints.up("sm")]: {
      bottom: "-10vw",
    },
    [theme.breakpoints.up("md")]: {
      bottom: "-18vw",
    },
    [theme.breakpoints.up("lg")]: {
      bottom: "-235px",
    },
  },
  heroHome: {
    [theme.breakpoints.only("xs")]: {
      bottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "-2vw",
    },
    [theme.breakpoints.up("sm")]: {
      bottom: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: "130%",
      right: "-220px",
      bottom: "-18vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "130%",
      right: "-220px",
      bottom: "-200px",
    },
  },

  content: {
    zIndex: 500,
    flexGrow: 1,
    padding: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },

  contentHome: {
    flexDirection: "column",

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(2),
    },
  },

  message: {
    fontSize: theme.atlasGuru.fontSizes.body1.lg,
    lineHeight: 1.2,
    marginTop: theme.spacing(),

    [theme.breakpoints.down("xs")]: {
      maxWidth: "275px",
    },
    [`@media (max-width:${theme.breakpoints.values.xsm}px)`]: {
      maxWidth: "250px",
    },
  },

  left: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "start",

    [theme.breakpoints.up("sm")]: {
      "& .icon": { marginTop: theme.spacing() },
    },
  },

  right: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    marginTop: theme.spacing(3),

    [theme.breakpoints.down("sm")]: {
      alignSelf: "start",
    },
  },

  rightHome: {
    alignSelf: "start",
    marginLeft: theme.spacing(6),
    marginTop: 0,

    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },

  avatarContainer: {
    marginBottom: theme.spacing(),
    minWidth: "120px",
  },

  userAvatarImg: {
    borderRadius: "50%",
    border: `1px solid ${theme.palette.common.white}`,
    margin: `0 -4px`,
  },

  heading: {
    color: theme.palette.primary.main,
  },

  headingHome: {
    ...theme.typography.teaser,
  },

  smallIcon: { display: "flex", gap: theme.spacing() },

  button: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,

      "&:hover svg": {
        color: theme.palette.common.white,
      },
    },
  },

  buttonLabel: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    whiteSpace: "nowrap",
  },

  extraSmallDown: {
    display: "none",

    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },

  smallUp: {
    display: "none",

    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
});

export default makeStyles(styles);
