import { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import AtlasAnalytics from "@/lib/AtlasAnalytics";
import { Button, Grid, Input, Loading, Section, Typography } from "@/ui";

import useStyles from "./styles";
import classNames from "classnames";
// import Script from "next/script";

const mailchimpUrl =
  "https://atlasguru.us7.list-manage.com/subscribe/post?u=894133f4b908b7ff765c19b43&id=d128033700&f_id=00bcf9e3f0";

// const popUpScriptUrl =
//   "https://chimpstatic.com/mcjs-connected/js/users/894133f4b908b7ff765c19b43/526490699e146c09c787fa5c3.js";

const atlasAnalytics = new AtlasAnalytics();

const EmailSignUp = ({ classes: rootClasses = {} }) => {
  const classes = useStyles({ classes: rootClasses });
  const [email, setEmail] = useState("");
  const onSubmit = (subscribe) => {
    subscribe({
      EMAIL: email,
    });

    atlasAnalytics.newsletterSignUp({
      name: "Interested Guru",
      email,
      message: "Newsletter Signup",
    });
  };

  return (
    <>
      {/* subcription pop up */}
      {/* <Script strategy="lazyOnload" src={popUpScriptUrl} id="mcjs" /> */}
      {/* subsription from inline */}
      <MailchimpSubscribe
        url={mailchimpUrl}
        render={({ subscribe, status }) => {
          const isSubmitting = status === "sending";
          const hasError = status === "error";
          const isSuccess = status === "success";

          return (
            <Section
              className={classNames(
                classes.root,
                isSuccess ? classes.succeeded : classes.signup
              )}
            >
              <Grid xs={12} item>
                <Typography className={classes.titleText} variant="h3">
                  Free Newsletter! Get Inspired From Fellow Travelers.
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <form className={classes.form}>
                  <Input
                    classes={{
                      root: classes.inputWrapper,
                      egText: classes.egText,
                      input: classNames(
                        classes.input,
                        email ? classes.filled : classes.placeholder
                      ),
                      notchedOutline: classes.notchedOutline,
                    }}
                    onChange={(event) => setEmail(event.target.value)}
                    error={hasError ? "Invalid Email Address" : ""}
                    fullWidth
                    margin="none"
                    name="signup"
                    placeholder="Enter your email here"
                    type="text"
                    value={email}
                  />
                  <Button
                    className={classNames(
                      classes.button,
                      hasError ? classes.buttonError : ""
                    )}
                    style={{ lineHeight: 1.1 }}
                    color="secondary"
                    disabled={isSubmitting}
                    error={hasError}
                    type="submit"
                    variant="contained"
                    onClick={() => onSubmit(subscribe)}
                  >
                    Sign Me Up
                    {isSubmitting && <Loading className={classes.loading} />}
                  </Button>
                </form>
                <div className={classes.successMessage}>
                  <Typography variant="h3">
                    Thanks! Your newsletter subscription has been confirmed.
                  </Typography>
                </div>
              </Grid>
            </Section>
          );
        }}
      />
    </>
  );
};

export default EmailSignUp;
