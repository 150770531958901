import { useEffect } from "react";

import { useAds } from "@/lib/ads";

import { adUnitSuffix, sizesAds, displayPlaceholders } from "./constants";

// styles
import useStyles from "./styles";
import Script from "next/script";

const AdUnit = (props) => {
  const {
    className,
    onAdLoaded = () => {},
    adUnit = "",
    placement = "",
    targeting = {},
    variant,
    style,
    ...rest
  } = props;
  const dashedPlacement = placement ? `-${placement}` : "";
  const elementId = `ad-${variant}-${adUnit}${dashedPlacement}`;
  const classes = useStyles({ classes: rest.classes || {} });

  const { gptHasInited, registerAdSlot } = useAds();

  // register GPT targeting
  useEffect(() => {
    if (gptHasInited) {
      registerAdSlot({
        adUnit: `${adUnit}-${adUnitSuffix}`,
        elementId,
        sizes: sizesAds[variant || "default"],
        targeting,
        onSlotLoaded: onAdLoaded,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gptHasInited]);

  return (
    <>
      <Script
        id="adsbygoogle"
        strategy="lazyOnload"
        data-ad-client="ca-pub-1911985615508498"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      />

      <Script
        id="gpt"
        strategy="lazyOnload"
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
      />
      <aside
        id={elementId}
        className={`${classes.root} ${classes[variant || "hidden"]} ${
          className || ""
        }`}
        style={style}
      >
        {displayPlaceholders && <div className={classes.placeholder} />}
      </aside>
    </>
  );
};

export default AdUnit;
